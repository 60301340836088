.swarmobot-container {
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 95vh;
  background-image: url(../../../public/images/Swarmobot_Background.jpg);
  background-position: 100%;
  display: block;

}

.swarmobot__title--wrapper {
  position: relative;
  font-family: sans-serif;
}

.swarmobot__title {
  color: #fff;
  font-weight: 550;
  text-align: left;
  margin-left: 0px;
  font-size: 3.5rem;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.swarmobot__title--stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.150);
  position: absolute;
  top: 41%;
  left: 35px;
  transform: translateY(-50%);
}

/* BOT ANIMATION */

.swarmobot__animation {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.1px);
  -webkit-backdrop-filter: blur(3.1px);

  padding: 100px;

  display: flex;
  flex-direction: column;

  justify-self: center;
  align-self: center;
  grid-area: swarmobot__animation;
}

.swarmobot__animation_cap {
  position: relative;
  z-index: 2;
}

.swarmobot__animation_body {
  position: relative;
  z-index: 1;
}

/* DESCRIPTION */

.swarmobot__description--wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "swarmobot__animation swarmobot__text";
  justify-items: start;
  align-items: center;

  font-size: 1.3rem;
  color: #fff;
}

.swarmobot__text {
  justify-self: center;
  align-self: center;
  grid-area: swarmobot__text;
  margin-top: -60px;
  padding-right: 20%;
  display:grid;
}


/* LINKS */

.swarmobot__links--wrapper {
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  color: #fff;
  font-size: 1.3rem;
}

.swarmobot__links--text {
  text-align: center;
  width: 360px;
  margin: 0 30px;
  padding: auto;
}

.swarmobot__links {
  text-decoration: none;
  color: #08D3FF;
}

/* MOBILE */

@media screen and (max-width: 960px) {

  .swarmobot-container {
    height: 100%;
  }

  .swarmobot__title {
    padding: 10% 0 0 0;
    margin: 0;
    text-align: center;
    font-size: 2.2rem;
  }

  .swarmobot__description--wrapper {
    margin-top: 120px;
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "swarmobot__animation"
      "swarmobot__text";

  }

  .swarmobot__description {
    position: relative;
    word-spacing: 1px;
    width: 70%;
    text-align: justify;
    padding: 0;
    line-height: 30px;
    margin: auto;
    margin-top: 70px;
    font-size: 1.3rem;
    right: 0;
  }

  .swarmobot__links--wrapper {
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 1.3rem;
    padding-top: 50px;
  }

  .swarmobot__text {
    padding-right: 0;
  }

  .swarmobot__animation {
    padding: 80px;
  }

  .swarmobot__links--text {
    width: 300px;
    padding-top: 30px;
  }
}