/* Publication card components */


.publications__card--container {
    position: relative;

    color: #000000;
    margin: auto;
    margin-bottom: 20px;
    width: 89vw;
    cursor: pointer;

    border: 1px solid black;
    transition: all 0.5s ease-in-out;


}

.publications__card--wrapper {
    display: grid;
    grid-template-columns: 0.3fr 1.9fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    justify-items: start;

    grid-template-areas:
        "publcations__card--btn publications__card--title";
}

.publications__card--wrapperDark {

    color: #fff;
    background-color: #000000;

    transition: all 0.3s ease-in-out;
}


.publications__card--title {
    margin: 5px 0;
    display: grid;
    font-size: 1.9rem;
    text-transform: uppercase;
    text-align: center;

    justify-self: start;
    align-self: center;
    grid-area: publications__card--title;
}

.publications__card--info {
    font-size: 1.2rem;
    height: 650px;
    overflow: auto;
    transition: all 0.4s ease-in-out;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.publications__card--info::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.publications__card--link {
    margin: 25px 45px;
}

.publications__card--info p {
    padding: 30px 0;
}

.publications__card--info a {
    color: #08D3FF;
    text-decoration: none;
}

.publcations__card--btn {
    display: grid;
    background: transparent;
    border-color: transparent;
    font-size: 1.3rem;
    justify-self: center;
    align-self: center;

    grid-area: publcations__card--btn;
}



/* Publications Component classes */

.publications__title--wrapper {
    position: relative;
    font-family: sans-serif;
    margin-bottom: 70px;

}

.publications__title {
    color: #000000;
    font-weight: 550;
    text-align: center;
    font-size: 4rem;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
}

.publications__title--stroke {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
}

/* MOBILE */

@media screen and (max-width: 960px) {
    .publications__title {
        padding: 10% 0 0 0;
        margin: 0;
        text-align: center;
        font-size: 3.2rem;
    }

    .publications__card--title {
        font-size: 1.5rem;
    }

    .publcations__card--btn {
        font-size: 1.3rem;
    }
}