.footer-container {
    padding: 30px 50px;
    background-color: #151515;
    background-attachment: scroll;
    width: 100%;
    color: #fff;

    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr;
    grid-template-rows: 1fr;
    gap: 0px 30px;
    grid-template-areas:
        "footer__email footer__address footer__links";
}


.footer__headline {
    font-size: 2.5rem;

    justify-self: center;
    align-self: center;
    grid-area: footer__headline;
}

.footer__email {
    justify-self: center;
    align-self: center;
    display: flex;

    grid-area: footer__email;

}

.footer__address {
    justify-self: center;
    align-self: center;
    display: flex;
    grid-area: footer__address;
}

.footer__info {

    display: flex;
    align-items: center;
    font-size: 1.3rem;
    margin-left: 10px;
    text-decoration: none;
    color: #fff;
}

.footer__infoIcon {
    margin-top: 5px;
}

.footer__links {
    justify-self: center;
    align-self: center;
    display: flex;
    grid-area: footer__links;

    padding-top: 10px;
    gap: 0 30px;
}

/* MOBILE */

@media screen and (max-width: 960px) {}

@media screen and (max-width: 1150px) {
    .footer-container {
        padding: 0;
        padding-top: 50px;

        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0.8fr 0.8fr 1.2fr;

        grid-template-areas:
            "footer__headline"
            "footer__email"
            "footer__address"
            "footer__links";


        background-size: cover;
        background-image: url(../../../public/images/Contacts_Background.jpg);
        background-attachment: scroll;
    }

    .footer__headline {
        justify-self: center;
        align-self: normal;

        font-size: 2rem;
    }

    .footer__email {
        margin-top: 20px;
    }

    .footer__address {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .footer__links {
        margin: 0 15px;
    }
}