.media-container {
    height: 100%;
    background-size: cover;
    background-image: url(../../../public/images/Media_background.png);
    background-position: bottom;
    background-attachment: scroll;


    width: 100%;
    margin-top: 80px;
    padding: 80px 0;
    padding-bottom: 130px;
}

.media__headline--wrapper {
    position: relative;
    font-family: sans-serif;
}

.media__title {
    color: #ffffff;
    font-weight: 550;
    text-align: center;
    font-size: 5rem;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    padding-bottom: 70px;
}

/* NEWS SECTION */

.media__news--headline {
    color: #ffffff;
    font-size: 3.5rem;
    margin: 40px 0;
    text-align: center;
}


.media__news--container {
    margin: auto;
    width: 85%;

    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.32);
    padding: 40px 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 0.3fr 2.4fr 0.3fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". . .";
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    height: 600px;
}

.media__news--container a {
    display: block;
    color: #08D3FF;
    text-decoration: none;
    font-size: 1.5rem;
    margin-top: 10%;
}


/* Button styles */

.media__btn {
    background-color: #000;
    color: #000;
    text-align: center;
    background: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
}

.btn--prev {
    display: grid;
    width: 100%;
    height: 100%;
}

.media__article {
    width: 100%;
    height: 100%;

    overflow: hidden;
    display: flex;


}

.btn--next {

    display: grid;
    width: 100%;
    height: 100%;
}


/* MOBILE */

@media screen and (max-width: 960px) {

    .media-container {
        padding: 20px 0;
        padding-bottom: 100px;
    }

    .media__news--container {
        width: 90%;
        height: 550px;
        overflow-y: scroll;
    }

    .media__title {
        font-size: 4rem;
        margin: 0;
        text-align: center;
    }

    .media__news--headline {
        font-size: 2rem;
        margin: auto;
        color: #ffffff;
        margin: 50px 0;
    }


    .media__news--container p {
        font-size: 1.5rem;
        padding-bottom: 20px;
        padding-left: 7%;
    }

    .media__news--container a {
        font-size: 1.2rem;
        margin: 0 2%;
        padding-left: 7%;
    }

    .media__btn {
        width: 10%;
        font-size: 1.2rem;
        padding: 10px 10px;
    }

}