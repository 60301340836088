.navbar {
  background: #000000;
  height: 12.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px 20px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 160px;
  padding-left: 130px;
}

.navbar-logoText {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: grid;
  padding: 25px;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 13vh;
  border-bottom: 2px solid transparent;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.nav-language {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
}


@media screen and (min-width: 960px) {

  /* Highlight on hover on NavBar*/
  .nav-item {
    background-image: linear-gradient(#262626 0 0);
    background-size: 200% .08em;
    background-position: 200% 100%;
    background-repeat: no-repeat;
    transition: background-size .3s, background-position .0001s .3s;
  }

  .nav-item:hover {
    color: #000000;
    transition: background-size .1s .1s, background-position .1s;
    background-size: 200% 100%;
    background-position: 100% 100%;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {

    color: #000000;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-language {
    margin: 0;

  }
}