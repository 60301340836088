.aboutUs-container {
    height: 95vh;
    background-size: cover;
    background-image: url(../../../public/images/AboutUs_Background.jpg);
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
}

.aboutUs__wrapper {
    display: grid;
    align-content: center;
    justify-content: center;
}

.aboutUs__title--wrapper {
    position: relative;
    display:grid;
    align-content: center;
    justify-content: center;
    font-family: sans-serif;
}

/* HEADLINE TEXT */


.aboutUs__title {
    color: #fff;
    font-weight: 550;
    text-align: right;
    font-size: 4rem;
    position: relative;
    z-index: 2;
    line-height: 3rem;
    text-transform: uppercase;
    height: fit-content;
}


.aboutUs__content {
    position: relative;
    display:grid;
    align-content: center;
    justify-content: center;
}

.aboutUs__text {
    text-align: center;
    padding-left: 280px;
    padding-right: 280px;
    margin-top: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
}

/* DESCRIPTION  */

.aboutUs__description {
    font-size: 1.3rem;
    color: #fff;
}

.aboutUs__description--highlight {
    color: #FF0327;
}

.aboutUs__description--headline {
    font-size: 1.45rem;
    color: #fff;
}

/* MOBILE */

@media screen and (max-width: 960px) {
    .aboutUs-container {
        height: 100%;
    }

    .aboutUs__title {
        padding: 10% 0 0 0;
        margin: 0;
        text-align: center;
        margin: auto;
        font-size: 4rem;
    }

    /* CHART AND TEXT */

    .aboutUs__text {
        width: 100%;
        margin-top: 0;
    }

    .aboutUs__chart {
        display: block;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .aboutUs__content {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
    }

    /* HEADLINE */
    .aboutUs__description--headline {
        text-align: center;
        font-weight: 500;
        font-size: 1.7rem;

    }

    .aboutUs__description {
        text-align: justify;
        word-spacing: 1px;
        line-height: 30px;
        width: 70%;
        padding: 0;
        margin: auto;
        font-size: 1.3rem;
        padding-bottom: 70px;
    }
}