@font-face {
  font-family: 'Webnar';
  src: local('Webnar Medium'), local('Webnar-Medium'),
    url('./fonts/Webnar-Medium.eot') format('embedded-opentype'),
    url('./fonts/Webnar-Medium.woff2 ') format('woff2'),
    url('./fonts/Webnar-Medium.woff') format('woff'),
    url('./fonts/Webnar-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Webnar', sans-serif;

}