/* TeamCard Component classes */

.team__img {
    background-color: #D9D9D9;
    border-radius: 50%;
    width: 70%;
    margin: 10px 0px 10px 5px;
}

.team__card--wrapper {
    margin-top: 80px;
    align-items: center;
    justify-content: center;

    margin-left: 3%;
    margin-right: 3%;
    display: flex;

    cursor: pointer;

}

.team__card--wrapper:hover {
    transition: transform 0.2s;
    transform: scale(1.05);
}

/* Team Component classes */

.team-container {
    padding: 6% 0;
}

.team__cardBio {
    text-align: center;
    margin-left: -20px;
    width: 280px;
}

.team__cardName {
    font-size: 1.4rem;
    font-weight: 550;
}

.team__cardPosition {
    font-size: 1rem;
}


.team__card--row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin: 0 70px;


    gap: 0px 10px;
}


.team__headline--wrapper {
    position: relative;
    font-family: sans-serif;
    display: grid;
    align-content: center;
    justify-content: center;
}

.team__title {
    color: #000000;
    font-weight: 550;
    text-align: right;
    font-size: 4rem;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
}

.team__title--stroke {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 54%;
    transform: translateY(-50%);
}

span {
    padding: 0;
    margin: 0;
}




/* Mobile Team Component classes */

@media screen and (max-width: 960px) {
    .team__card--row {
        flex-direction: column;

    }

    .team__title {
        margin: 0;
        text-align: center;
    }
}