.links-container {
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 95vh;
  background-image: url(../../../public/images/Links_background.png);
  background-position: 100%;

  display:grid;
  align-content: center;
  justify-content: center;

}

.links__title--wrapper {
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  font-family: sans-serif;
}

.links__title {
  color: #fff;
  font-weight: 550;
  text-align: left;
  font-size: 4rem;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.links__title--stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.150);
  position: absolute;
  top: 54%;
  left: 45%;
  transform: translateY(-50%);
}


.image__wrapper {
  padding-bottom: 35px;
}

/* DESCRIPTION */

.links__description--wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "links__animation links__text";
  justify-items: start;

  width: 100%;
  font-size: 1.3rem;
  color: #fff;
  padding-top: 120px;
}

.links__text {
  justify-self: center;
  align-self: center;
  grid-area: links__text;
  padding-right: 10%;
}


/* LINKS */

.links__links--wrapper {
  padding-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  color: #ffffff;
  font-size: 1.3rem;
}

.links__links--text {
  text-align: center;
  width: 360px;
  margin: 0 30px;
  padding: auto;
}

.links__links {
  text-decoration: none;
  color: #ffffff;
}

/* MOBILE */

@media screen and (max-width: 960px) {

  .links-container {
    height: 100%;
  }

  .links__title {
    padding: 25% 0 0 0;
    margin: 0;
    text-align: center;
    font-size: 3.2rem;
  }

  .links__description--wrapper {
    margin-top: 120px;
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "links__animation"
      "links__text";

  }

  .links__description {
    position: relative;
    word-spacing: 1px;
    width: 70%;
    text-align: justify;
    padding: 0;
    line-height: 30px;
    margin: auto;
    margin-top: 70px;
    font-size: 1.3rem;
    right: 0;
  }

  .links__links--wrapper {
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 1.3rem;
    padding-top: 50px;
  }

  .links__text {
    padding-right: 0;
  }

  .links__animation {
    padding: 80px;
  }

  .links__links--text {
    width: 300px;
    padding-top: 30px;
  }
}